import React from 'react';
import PropTypes from 'prop-types';
import {IMAGE_UPLOAD_THUMBNAIL_FOLDER} from "../../constants";

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const propTypes = {
    image: PropTypes.object.isRequired
};

const UploadedImage = ({image}) => {
    return (
        <div style={thumb}>
            <div style={thumbInner}>
                <img src={IMAGE_UPLOAD_THUMBNAIL_FOLDER + '/' + image.hash + '.jpg'}/>
            </div>
        </div>
    );
};
UploadedImage.propTypes = propTypes;
export default UploadedImage;