import React, {useState, useEffect} from 'react';
import axios from 'axios';
import 'babel-polyfill';
import {toast} from 'react-toastify';

const useEndUserData = (token) => {

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [refetchTriggerFlag, _triggerRefetch] = useState(false);

    const refetch = () => {
        _triggerRefetch(!refetchTriggerFlag);
    };

    useEffect(() => {
        (async () => {
            try {
                const response = await axios.get('/access-data/' + token);
                setData(response.data);
                console.log("ABC");
                console.log(response.data);
            } catch (e) {
                console.error(e);
                toast.error(e.message);
                setError(e);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [refetchTriggerFlag]);

    return {data, isLoading, error, refetch}
};
export default useEndUserData;