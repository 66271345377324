import React, {useState} from 'react';
import ReactDOM from 'react-dom';

import axios from 'axios';
import {toast} from 'react-toastify';
import 'babel-polyfill';

import Container from "react-bootstrap/Container";
import useEndUserData from "./Hooks/useEndUserData";
import CustomerView from "./Components/Instrument/CustomerView/CustomerView";
import Table from "react-bootstrap/Table";
import useEndUserInstrumentItems from "./Hooks/useEndUserInstrumentItems";
import InstrumentTimeline from "./Components/Timeline/InstrumentTimeline";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "@material-ui/core/Button";
import Modal from "react-bootstrap/Modal";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Moment from "react-moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import useEndUserInstrumentOwned from "./Hooks/useEndUserInstrumentOwned";


const App = () => {
    const [selectedDInstrumentId, setSelectedDInstrumentId] = useState(null);
    const [selectedOwnedDInstrumentId, setSelectedOwnedDInstrumentId] = useState(null);
    const [emailing, setEmailing] = useState(false);
    const [message, setMessage] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [title, setTitle] = useState('');
    const [ownedInstruments, setOwnedInstruments] = ([]);
    const [value, setValue] = React.useState(0);

    const {data, isLoading: isLoadingData, error: dataError} = useEndUserData(window.token);
    const {items, isLoading: isLoadingItems, error: itemsError} = useEndUserInstrumentItems(window.token, selectedDInstrumentId);
    const {owned, isLoading: isLoadingOwned, ownerError} = useEndUserInstrumentOwned(window.token, selectedOwnedDInstrumentId);

    const handleTitle = event => {
        setTitle(event.target.value);
    };

    const handleMessage = event => {
        setMessage(event.target.value);
    };

    const handleEmailAddress = event => {
        setEmailAddress(event.target.value);
    };

    const handleModal = (item) => {
        setEmailing(true);
        setEmailAddress(item['instrument'] && item['instrument'].customer.contacts[0].email_address);
    };

    const sendEmail = async () => {
        console.log("sending email");
        console.log(emailAddress);
        console.log(title);
        console.log(message);
        try {
            const response = await axios.post('/end-user-send-email', {
                title: title,
                message: message,
                emailAddress: emailAddress
            });
            console.log(response.data);
        } catch (e) {
            console.log(e);
            toast.error("Couldn't send invoice");
        }
        toast.success("Email was sent")
    };

    const getdInstrumentLastTuned = async (dInstr) => {
        try {
            const response = await axios.post('/instrument-last-tuning', {
                id: dInstr.id
            });
            console.log(response);
        } catch (e) {

        }
    };

    const lastTuning = (items) => {
        let tuning = null;
        items.forEach(i => {
            if (i.discr == 'tuning') {
                tuning = i.created;
            }
        });
        if (tuning != null) return <Moment format={"DD.MM.YYYY"}>{tuning}</Moment>;
        if (tuning == null) return <p>No tuning registered</p>;
        return null;
    };

    return (
        <Container>
            <BottomNavigation
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                showLabels
            >
                <BottomNavigationAction label="My Instruments"/>
                <BottomNavigationAction label="My Institutions"/>
            </BottomNavigation>

            {dataError &&
            <h4 className={"text-danger"}>There was an error while retrieving the data</h4>
            }

            {value == 0 ?
                <div>
                    <h3 style={{paddingTop: "2em"}}>Your instruments</h3>
                    <h3 style={{color: "red"}}>Work in progress. Not fully functional as of today</h3>
                    <Row>
                        <Col xs={12} md={6}>
                            {isLoadingData &&
                            <>
                                <p className={"text-center my-5"}><CircularProgress/></p>
                            </>
                            }
                            {!isLoadingData && !dataError &&
                            <>
                                <div>
                                    <Table striped hover className={"mb-3 p-2"}>
                                        <thead>
                                        <tr>
                                            <th><p>Make</p></th>
                                            <th><p>Serial Number</p></th>
                                            <th><p>Last tuned</p></th>
                                            <th><p>Room</p></th>
                                            <th><p>Location</p></th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        {data.customer_access_list.map(customer_access => (
                                            <tbody>
                                            {customer_access.customer.owned_instrument.map(dInstrument => (
                                                <tr key={dInstrument.id}>
                                                    <td>{dInstrument.instrument.make} {dInstrument.model}</td>
                                                    <td>{dInstrument.instrument.serial_number ? dInstrument.instrument.serial_number : 'No serial'}</td>
                                                    <td>{lastTuning(dInstrument.d_item)}</td>
                                                    <td>{dInstrument.room ? dInstrument.room : "No room"}</td>
                                                    <td>{console.log(customer_access.customer)}{customer_access.customer && customer_access.customer.addresses ? customer_access.customer.addresses[0].line_one + ', ' + customer_access.customer.addresses[0].postal_code + ', ' + customer_access.customer.addresses[0].postal_town  : "No location"}</td>
                                                    <td>
                                                        {dInstrument.discarded ? <p style={{color: "red"}}>Discarded</p> :
                                                            <div className={"d-flex justify-content-end"}>
                                                                <Button style={{
                                                                    fontSize: '0.6em',
                                                                    background: '#409bf2',
                                                                    color: 'white',
                                                                    width: '0.3em'
                                                                }}
                                                                        variant='contained'
                                                                        onClick={() => setSelectedOwnedDInstrumentId(dInstrument.id)}
                                                                >View</Button>
                                                            </div>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        ))
                                        }</Table>
                                </div>
                            </>
                            }
                        </Col>
                        <Col md={12} lg={6}>
                            <div className={"p-5 mb-3"}>
                                {isLoadingOwned &&
                                <h1 className={"mt-5 d-flex justify-content-center"}><CircularProgress/></h1>
                                }

                                {!isLoadingOwned && owned &&
                                <>

                                    <h2>
                                        <b>{owned['instrument'] && owned['instrument'].instrument.make && owned['instrument'].instrument.make.make}</b> {owned['instrument'] && owned['instrument'].model}
                                    </h2>
                                    <p>#{owned['instrument'] && owned['instrument'].instrument.serial_number}</p>
                                    <h2>{}</h2>
                                    <p>Room: {owned['instrument'] && owned['instrument'].room}</p>
                                    <Button
                                        variant='contained'
                                        style={{
                                            background: '#409bf2',
                                            fontSize: '0.6em',
                                            color: 'white',
                                            borderRadius: 3,
                                            border: 0,
                                            height: 48,
                                            margin: '5px 5px',
                                            padding: '0 30px',
                                            boxShadow: '0 3px 5px 2px rgba(64, 155, 242, .2)'
                                        }}
                                        onClick={() => handleModal(owned)}
                                    >Contact</Button>
                                    <Modal show={emailing}
                                           onHide={() => setEmailing(false)}
                                           verticallycenter={"true"}
                                    >
                                        <Modal.Header closeButton><p style={{marginRight: '10px'}}>Send email to</p>
                                            <FormControl>
                                                <Select value="Henrik Eide AS">
                                                    <MenuItem value="Henrik Eide AS">
                                                        <p style={{marginBottom: '0'}}>Henrik Eide AS</p>
                                                    </MenuItem>
                                                </Select>
                                            </FormControl><br/>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <TextField
                                                style={{width: '50%'}}
                                                id="outlined-multiline-flexible"
                                                label="Email from"
                                                multiline
                                                rowsMax="4"
                                                value={emailAddress}
                                                onChange={handleEmailAddress}
                                                variant="outlined"
                                            />
                                            <br/><br/>
                                            <TextField
                                                style={{width: '50%'}}
                                                id="outlined-multiline-flexible"
                                                label="Title"
                                                multiline
                                                rowsMax="4"
                                                value={title}
                                                onChange={handleTitle}
                                                variant="outlined"
                                            /><br/><br/>
                                            <TextField
                                                style={{width: '100%'}}
                                                id="outlined-multiline-static"
                                                label="Message"
                                                multiline
                                                rows="4"
                                                defaultValue="#"
                                                variant="outlined"
                                                value={message}
                                                onChange={handleMessage}
                                            /><br/>
                                            <Button
                                                variant='contained'
                                                style={{
                                                    float: 'right',
                                                    background: '#409bf2',
                                                    fontSize: '0.6em',
                                                    color: 'white',
                                                    borderRadius: 3,
                                                    border: 0,
                                                    height: 48,
                                                    margin: '5px 5px',
                                                    padding: '0 30px',
                                                    boxShadow: '0 3px 5px 2px rgba(64, 155, 242, .2)'
                                                }}
                                                onClick={() => sendEmail()}
                                            >Send</Button>
                                        </Modal.Body>
                                    </Modal>
                                    <InstrumentTimeline items={owned['items'] ? owned['items'] : []}/>


                                </>
                                }

                                {!isLoadingItems && !items &&
                                <>{/*TODO: What to do when empty*/}</>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>

                : <div>
                    <h3 style={{paddingTop: "2em"}}>Institutions and users
                        registered to this email</h3>
                    <Row>
                        <Col md={12} lg={6}>

                            {isLoadingData &&
                            <>
                                <p className={"text-center my-5"}><CircularProgress/></p>
                            </>
                            }
                            {!isLoadingData && !dataError &&
                            <>
                                {
                                    data.customer_access_list.map(customer_access => (
                                        <div className={"mb-3 border rounded p-2"} key={customer_access.id}>
                                            <CustomerView customer={customer_access.customer}
                                                          showContacts={false}/>
                                            <Table striped hover>
                                                <thead>
                                                <tr>
                                                    <th><p>Make</p></th>
                                                    <th><p>Serial Number</p></th>
                                                    <th><p>Last tuned</p></th>
                                                    <th><p>Room</p></th>
                                                    <th></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {customer_access.customer.d_instruments.map(dInstrument => (
                                                    <tr key={dInstrument.id}>
                                                        <td>{dInstrument.instrument.make} {dInstrument.model}</td>
                                                        <td>{dInstrument.instrument.serial_number ? dInstrument.instrument.serial_number : 'No serial'}</td>
                                                        <td>{lastTuning(dInstrument.d_item)}</td>
                                                        <td>{dInstrument.room ? dInstrument.room : "No room"}</td>
                                                        <td>
                                                        {dInstrument.discarded ? <p style={{color: "red"}}>Discarded</p> :
                                                            <div className={"d-flex justify-content-end"}>
                                                                <Button style={{
                                                                    fontSize: '0.6em',
                                                                    background: '#409bf2',
                                                                    color: 'white',
                                                                    width: '0.3em'
                                                                }}
                                                                        variant='contained'
                                                                        onClick={() => setSelectedDInstrumentId(dInstrument.id)}
                                                                >View</Button>
                                                            </div>
                                                        }
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    ))
                                }
                            </>
                            }
                        </Col>
                        <Col xs={12} md={6}>
                            <div className={"p-5 mb-3"}>
                                {isLoadingItems &&
                                <h1 className={"mt-5 d-flex justify-content-center"}><CircularProgress/></h1>
                                }

                                {!isLoadingItems && items &&
                                <>
                                    <h2>
                                        <b>{items['instrument'] && items['instrument'].instrument.make && items['instrument'].instrument.make.make}</b> {items['instrument'] && items['instrument'].model}
                                    </h2>
                                    <p>#{items['instrument'] && items['instrument'].instrument.serial_number}</p>
                                    <h2>{}</h2>
                                    <p>Room: {items['instrument'] && items['instrument'].room}</p>
                                    <Button
                                        variant='contained'
                                        style={{
                                            background: '#409bf2',
                                            fontSize: '0.6em',
                                            color: 'white',
                                            borderRadius: 3,
                                            border: 0,
                                            height: 48,
                                            margin: '5px 5px',
                                            padding: '0 30px',
                                            boxShadow: '0 3px 5px 2px rgba(64, 155, 242, .2)'
                                        }}
                                        onClick={() => handleModal(items)}
                                    >Contact</Button>
                                    <Modal show={emailing}
                                           onHide={() => setEmailing(false)}
                                           verticallycenter={"true"}
                                    >
                                        <Modal.Header closeButton><p style={{marginRight: '10px'}}>Send email to</p>
                                            <FormControl>
                                                <Select value="Henrik Eide AS">
                                                    <MenuItem value="Henrik Eide AS">
                                                        <p style={{marginBottom: '0'}}>Henrik Eide AS</p>
                                                    </MenuItem>
                                                </Select>
                                            </FormControl><br/>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <TextField
                                                style={{width: '50%'}}
                                                id="outlined-multiline-flexible"
                                                label="Email from"
                                                multiline
                                                rowsMax="4"
                                                value={emailAddress}
                                                onChange={handleEmailAddress}
                                                variant="outlined"
                                            />
                                            <br/><br/>
                                            <TextField
                                                style={{width: '50%'}}
                                                id="outlined-multiline-flexible"
                                                label="Title"
                                                multiline
                                                rowsMax="4"
                                                value={title}
                                                onChange={handleTitle}
                                                variant="outlined"
                                            /><br/><br/>
                                            <TextField
                                                style={{width: '100%'}}
                                                id="outlined-multiline-static"
                                                label="Message"
                                                multiline
                                                rows="4"
                                                defaultValue="#"
                                                variant="outlined"
                                                value={message}
                                                onChange={handleMessage}
                                            /><br/>
                                            <Button
                                                variant='contained'
                                                style={{
                                                    float: 'right',
                                                    background: '#409bf2',
                                                    fontSize: '0.6em',
                                                    color: 'white',
                                                    borderRadius: 3,
                                                    border: 0,
                                                    height: 48,
                                                    margin: '5px 5px',
                                                    padding: '0 30px',
                                                    boxShadow: '0 3px 5px 2px rgba(64, 155, 242, .2)'
                                                }}
                                                onClick={() => sendEmail()}
                                            >Send</Button>
                                        </Modal.Body>
                                    </Modal>
                                    <InstrumentTimeline items={items['items'] ? items['items'] : []}/>
                                </>
                                }

                                {!isLoadingItems && !items &&
                                <>{/*TODO: What to do when empty*/}</>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
            }
        </Container>
    )
};
export default App;

ReactDOM.render(<App/>, document.getElementById('app'));