import React from "react";
import Tile from "./Tile";

const ContactTile = ({contact}) => {
    return (
        <Tile
            icon={contact.is_primary ? <><i className={"fas fa-user"}></i> <i className={"fas fa-star color-gold"}></i></> : <i className={"fas fa-user"}></i>}
            content={
                <>
                    <span className={"d-block"}>{contact.given_name + ' ' + contact.surname}</span>
                    <span className={"d-block"}><a
                        href={"tel:" + contact.phone_number}>{contact.phone_number}</a></span>
                    <span className={"d-block"}><a
                        href={"mailto:" + contact.email_address}>{contact.email_address}</a></span>
                </>
            }
        />
    );
};
export default ContactTile;