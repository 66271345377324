import React from 'react';
import PropTypes from 'prop-types';
import ItemEmployeeActionSummary from "../DInstrumentItem/ItemEmployeeActionSummary";
import {TimelineEvent} from "react-event-timeline";

const propTypes = {
    editTuning: PropTypes.func.isRequired,
    tuning: PropTypes.object.isRequired,
    time: PropTypes.object.isRequired,
};
const TuningTimelineEvent = ({tuning, editTuning, time}) => {

    return (
        <>

            <TimelineEvent
                icon={<i className={"fas fa-wave-square"}></i>}
                onIconClick={() => editTuning()}
                createdAt={time}
                title={<ItemEmployeeActionSummary item={tuning}/>}
            >
                {/*TODO: symbol next to logo in timenline. make dollar, sent and in progress logos*/}
                {/*<StatusIcon item={tuning}/>*/}

                Tuned
                to {tuning.to_frequency} Hz {tuning.from_frequency && <> from {tuning.from_frequency} Hz</>}

                {tuning.comment && <div className={"pt-2"}><p>{tuning.comment}</p></div>}
            </TimelineEvent>
        </>
    );
};
TuningTimelineEvent.propTypes = propTypes;
export default TuningTimelineEvent;