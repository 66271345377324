import React from "react";

import {TimelineEvent} from "react-event-timeline";
import ItemEmployeeActionSummary from "../DInstrumentItem/ItemEmployeeActionSummary";
import StatusIcon from "./StatusIcon";
import CustomerName from "../Customer/CustomerName";


const OwnershipTransferTimelineEvent = ({transfer, time}) => {
    return (
        <TimelineEvent
            icon={<>OT</>}
            onIconClick={() => {}}
            createdAt={time}
            title={<ItemEmployeeActionSummary item={transfer}/>}
        >
            <StatusIcon item={transfer}/>

            <p>
                To: {transfer.to_customer ?
                <CustomerName customer={transfer.to_customer}/> : <i>Unknown</i>}
                <br/>
                From: {transfer.from_customer ?
                <CustomerName customer={transfer.from_customer}/> : <i>Unknown</i>}
            </p>

        </TimelineEvent>
    );
};
export default OwnershipTransferTimelineEvent;