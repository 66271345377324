import React from "react";
import {TimelineEvent} from "react-event-timeline";
import PropTypes from 'prop-types';
import ItemEmployeeActionSummary from "../DInstrumentItem/ItemEmployeeActionSummary";
import StatusIcon from "./StatusIcon";

const propTypes = {
    note: PropTypes.object.isRequired,
    editNote: PropTypes.func,
    time: PropTypes.object.isRequired
};
const NoteTimelineEvent = ({note, editNote, time}) => {
    return (

        <TimelineEvent
            icon={<i className={"fas fa-pencil-alt"}></i>}
            onIconClick={() => editNote()}
            createdAt={time}
            title={<ItemEmployeeActionSummary item={note}/>}
        >
            <StatusIcon item={note}/>

            <p style={{whiteSpace: 'pre-line'}}>{note.text}</p>
        </TimelineEvent>

    );
};
NoteTimelineEvent.propTypes = propTypes;
export default NoteTimelineEvent;