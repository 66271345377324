import PropTypes from 'prop-types';
import React, {useState} from "react";
import CustomerName from "../../Customer/CustomerName";
import CustomerAddressTile from "./CustomerAddressTile";
import CustomerSymbol from "../../Customer/CustomerSymbol";
import {Button, Modal} from "react-bootstrap";
import ContactTile from "./ContactTile";
import ChildCustomerTile from "./ChildCustomerTile";
import ParentCustomerTile from "./ParentCustomerTile";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Fab from "@material-ui/core/Fab";
import EditCustomerEmail from "../../Customer/EditCustomerEmail";
import EditCustomerPhone from "../../Customer/EditCustomerPhone";
import EditCustomerName from "../../Customer/EditCustomerName";
import EditCustomerAddress from "../../Customer/EditCustomerAddress";
import EditCustomerPrimaryContact from "../../Customer/EditCustomerPrimaryContact";

const propTypes = {
    customer: PropTypes.object.isRequired,
    showContacts: PropTypes.bool
};
const defaultProps = {
    showContacts: true
};
const CustomerView = ({customer, showContacts}) => {


    const [viewDetails, setViewDetails] = useState(false);
    const [editInstrument, setEditInstrument] = useState(false);
    const [customerChange, setCustomerChange] = useState(customer);

    let address = null;

    if (customer.hasOwnProperty('primary_address') && customer.primary_address !== null) {
        address =
            <div>
                {customer.primary_address.line_one && customer.primary_address.postal_code ?
                    <>
                        {/* We can link to google maps if line_one and postal_code is set*/}

                        <a href={"https://google.com/maps/search/" + encodeURIComponent(customer.primary_address.line_one || '' + ' ' + customer.primary_address.postal_code || '')}
                           target={"_blank"}>
                            <i className={"fas fa-map-marker-alt"}></i>&nbsp;
                            <span>{customer.primary_address.line_one}</span><br/>
                            <span>{customer.primary_address.line_two || ''}</span><br/>
                            <span>{customer.primary_address.postal_code} {customer.primary_address.postal_town || ''}</span>
                        </a>
                    </>
                    :
                    <>
                        {/* If not, we merely print out what exists of information */}
                        {console.log(customer)}
                        <span>{customer.primary_address.line_one || ''}</span><br/>
                        <span>{customer.primary_address.line_two || ''}</span><br/>
                        <span>{customer.primary_address.postal_code || ''} {customer.primary_address.postal_town || ''}</span>
                    </>
                }
            </div>;
    }

    const contacts = customer.contacts.map(contact => (
        <ContactTile contact={contact} key={contact.id}/>
    ));

    return (
        <div>
            <Row>
                <Col xs={"auto"}>
                    <h4><CustomerSymbol customer={customer}/> <a href={'/customer/' + customer.id}><CustomerName customer={customer}/></a></h4>
                    {/*<h6 className={"text-muted"} style={{fontSize: "0.8rem"}}>
                        {customer.total_number_of_instruments}
                        {customer.total_number_of_instruments === 1 ? ' instrument' : ' instruments'}
                    </h6>*/}

                    {/*CONTACT INFO*/}
                    {!customer.given_name &&
                        <h6 className={"text-muted"}>
                            { (customer.contacts.length > 0) ? <>Contact: {customer.contacts['0'].given_name + " " + customer.contacts['0'].surname }</> : <></> }
                        </h6>
                    }

                    <h6 className={"text-muted"}>
                        {(customer.contacts.length > 0) ? <>Phone: {customer.contacts['0'].phone_number}</>: <>Phone: Not given</>}
                        {/*TODO: should be done like under, but does not work..*/}
                        {/*console.log(customer.contacts.find(is_primary => customer.contacts[is_primary] === true))*/}
                    </h6>
                    <h6 className={"text-muted"}>
                        {(customer.contacts.length > 0) ? <>Email: {customer.contacts['0'].email_address}</>: <>Email: Not given</>}
                    </h6>
                    {customer.parent && <h6 className={"mt-2"}><a
                        href={"/customer/" + customer.parent.id}> @{customer.parent.organizational_name}</a></h6>}

                    {showContacts &&
                    <Button
                        variant={"link"}
                        onClick={() => setViewDetails(!viewDetails)}
                    >
                        <i className={viewDetails ? 'fas fa-caret-up' : 'fas fa-caret-down'}></i>
                        {viewDetails ? 'Hide' : 'Show'} contacts
                    </Button>
                    }

                </Col>
                <Col className={"text-right"}>
                    {address && address}
                    {!address && <p className={"text-danger"}>No address on file.</p>}
                </Col>
                <Fab size={"small"} color="secondary" aria-label="edit" onClick={() => setEditInstrument(true)} style={{float:"Right"}}>
                    <i className="fas fa-pen"></i>
                </Fab>
            </Row>


            {
                showContacts && viewDetails &&
                <div className={"d-flex flex-align-stretch mt-2 flex-nowrap overflow-auto"}>
                    {contacts}
                </div>
            }
            <Modal show={editInstrument} onHide={() => setEditInstrument(false)}>
                <Modal.Header>
                    <h2>Edit customer</h2>
                </Modal.Header>
                <Modal.Body>
                    <EditCustomerEmail customer={customer} setCustomer={setCustomerChange}/>
                    <EditCustomerPhone customer={customer} setCustomer={setCustomerChange}/>
                    <EditCustomerName customer={customer} setCustomer={setCustomerChange}/>
                    <EditCustomerAddress customer={customer} setCustomer={setCustomerChange}/>
                    {customerChange.is_business && <EditCustomerPrimaryContact customer={customerChange} setCustomer={setCustomerChange}/>}
                </Modal.Body>
            </Modal>
        </div>
    );
};

CustomerView.propTypes = propTypes;
CustomerView.defaultProps = defaultProps;

export default CustomerView;