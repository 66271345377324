import React, {useState} from "react";
import axios from 'axios';
import PropTypes from 'prop-types';
import {Button, Col, Form, FormGroup, FormLabel, Row} from "react-bootstrap";
import {toast} from 'react-toastify';

const propTypes = {
    customer: PropTypes.object.isRequired,
    setCustomer: PropTypes.func.isRequired
};

const EditCustomerPhone = ({customer, setCustomer}) => {

    const [isEditing, setIsEditing] = useState(false);
    const [phone, setPhone] = useState(customer.contacts[0].phone_number);
    const [isUpdating, setIsUpdating] = useState(false);

    const updatePhone = async () => {
        setIsUpdating(true);
        try {
            await axios.patch('/api/customer-patch/' + customer.id + '/phone', {
                phone: phone
            });
            setIsEditing(false);
        } catch (e) {
            console.error(e);
            toast.error(e.message);
        }
        toast.success('Phone was updated.');
        setIsUpdating(false);
        window.location.reload();
    };

    const editView = <>
        <FormGroup>
            <FormLabel>Phone:</FormLabel>
            <Form.Control type={"text"} value={phone || ''} onChange={(e) => setPhone(e.target.value)}/>
        </FormGroup>
        <div className={"d-flex justify-content-between"}>
            <Button variant={"danger"} onClick={() => setIsEditing(false)}>Cancel</Button>
            <Button variant={"success"} onClick={() => updatePhone()} disabled={isUpdating}>
                {isUpdating ? <><i className={"fas fa-spinner fa-spin pr-1"}></i> Saving</> : 'Save'}
            </Button>
        </div>
    </>;

    const noEditView =
        <Row>
            <Col xs={4}><label>Phone:</label></Col>
            <Col xs={6}>{customer.contacts[0].phone_number}</Col>
            <Col xs={2}><Button size={"sm"}
                                variant={"link"}
                                onClick={() => setIsEditing(true)}>Edit</Button></Col>
        </Row>;

    return (
        <>
            {isEditing ? editView : noEditView}
        </>
    )
};
EditCustomerPhone.propTypes = propTypes;
export default EditCustomerPhone;