import React from 'react';
import {TimelineEvent} from "react-event-timeline";
import PropTypes from 'prop-types';
import ItemEmployeeActionSummary from '../DInstrumentItem/ItemEmployeeActionSummary'
import StatusIcon from './StatusIcon';
import CustomerName from "../Customer/CustomerName";

const propTypes = {
    rentalStop: PropTypes.object.isRequired,
    time: PropTypes.object.isRequired
};

const RentalStopTimelineEvent = ({rentalStop, time}) => {
    return (

        <TimelineEvent
            icon={<>R</>}
            inIconClick={() => console.log('Todo')}
            createdAt={time}
            title={<ItemEmployeeActionSummary item={rentalStop}/>}
            style={{backgroundColor: '#f8f9fa'}}
        >
            <StatusIcon item={rentalStop}/>

            <hr/>
            <h6><b>The instrument was returned to <CustomerName customer={rentalStop.customer}/>.</b></h6>
            <hr/>
        </TimelineEvent>
    );
};
RentalStopTimelineEvent.propTypes = propTypes;
export default RentalStopTimelineEvent;