import React, {useEffect, useState} from "react";
import axios from 'axios/index';
import PropTypes from 'prop-types';
import {Button, Col, Form, FormGroup, FormLabel, Row} from "react-bootstrap";
import {toast} from 'react-toastify/index';

const propTypes = {
    customer: PropTypes.object.isRequired,
    setCustomer: PropTypes.func.isRequired
};

const EditCustomerName = ({customer, setCustomer}) => {

    const [refetchFlag, _triggerRefetch] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [name, setName] = useState(null);
    const [surname, setSurname] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isOrganization, setIsOrganization] = useState(false);

    useEffect(() => {
        (async () => {
            if (customer.organizational_name == null) {
                setIsOrganization(false);
                setName(customer.given_name);
                setSurname(customer.surname);
            } else {
                setIsOrganization(true);
                setName(customer.organizational_name);
            }
        })();

    }, [refetchFlag]); // Rerun on refetchTriggerFlag change.

    const updateName = async () => {
        setIsUpdating(true);
        try {
            await axios.patch('/api/customer-patch/' + customer.id + '/name', {
                name: name,
                surname: surname
            });

            /*onst updatedInstrument = update(customer, {
                room: {$set: email}
            });*/
            setIsEditing(false);
        } catch (e) {
            console.error(e);
            toast.error(e.message);
        }
        toast.success('Name was updated.');
        setIsUpdating(false);
        window.location.reload();
    };

    const editView = <>
        <FormGroup>
            <FormLabel>Name:</FormLabel>
            <Form.Control type={"text"} value={name || ''} onChange={(e) => setName(e.target.value)}/>
            {!isOrganization &&
            <Form.Control type={"text"} value={surname || ''} onChange={(e) => setSurname(e.target.value)}/>}
        </FormGroup>
        <div className={"d-flex justify-content-between"}>
            <Button variant={"danger"} onClick={() => setIsEditing(false)}>Cancel</Button>
            <Button variant={"success"} onClick={() => updateName()} disabled={isUpdating}>
                {isUpdating ? <><i className={"fas fa-spinner fa-spin pr-1"}></i> Saving</> : 'Save'}
            </Button>
        </div>
    </>;

    const noEditView =
        <Row>
            <Col xs={4}><label>Name:</label></Col>
            <Col xs={6}>{name} {surname} </Col>
            <Col xs={2}><Button size={"sm"}
                                variant={"link"}
                                onClick={() => setIsEditing(true)}>Edit</Button></Col>
        </Row>;

    return (
        <>
            {isEditing ? editView : noEditView}
        </>
    )
};
EditCustomerName.propTypes = propTypes;
export default EditCustomerName;