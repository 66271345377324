import React from 'react';
import {TimelineEvent} from "react-event-timeline";
import PropTypes from 'prop-types';
import ItemEmployeeActionSummary from '../DInstrumentItem/ItemEmployeeActionSummary'
import StatusIcon from './StatusIcon';
import CustomerName from "../Customer/CustomerName";

const propTypes = {
    rentalStart: PropTypes.object.isRequired,
    time: PropTypes.object.isRequired
};

const RentalStartTimeLineEvent = ({rentalStart, time}) => {
    return (

        <TimelineEvent
            icon={<>R</>}
            inIconClick={() => console.log('Todo')}
            createdAt={time}
            title={<ItemEmployeeActionSummary item={rentalStart}/>}
            style={{backgroundColor: '#f8f9fa'}}
        >
            <StatusIcon item={rentalStart}/>

            <hr/>
            <h6><b>The instrument was rented out to <CustomerName customer={rentalStart.rented_to_customer}/>.</b></h6>
            <hr/>
        </TimelineEvent>
    );
};
RentalStartTimeLineEvent.propTypes = propTypes;
export default RentalStartTimeLineEvent;