import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    customer: PropTypes.object.isRequired
};

const CustomerName = (props) => {
    return <>{props.customer.is_business ? (
        props.customer.organizational_name
    ) : (
        props.customer.given_name + ' ' + props.customer.surname
    )}</>;
};

CustomerName.propTypes = propTypes;
export default CustomerName;