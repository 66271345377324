import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    item: PropTypes.object.isRequired
};


const cogStyle = {
    position: 'relative',
    fontSize: '1.5em',
    left: '-83px',
    top: '-56px'

};

const dollarStyle = {
    position: 'relative',
    left: '-95px',
    top: '-59px',
};
const checkLabelStyle = {
    position: 'relative',
    left: '-92px',
    top: '-69px',
    paddingTop: '1rem',
    paddingRight: '0.25rem',
    paddingBottom: '1rem',
    paddingLeft: '1rem',
    margin: 0
};

const container = {
    position: 'absolute'
};

const cogs = (
    <div style={container}>
        <i className={"fas fa-cogs"} style={cogStyle}></i>
    </div>
);

const dollar = (
    <div style={container}>
        <i className={"fas fa-dollar-sign"} style={dollarStyle}></i>
    </div>
);


const checkbox = (
    <div style={container}>
        <label
            style={checkLabelStyle}
        >
            <input
                type={"checkbox"}/>
        </label>
    </div>
);

const nothing = (<></>);


const StatusIcon = ({item}) => {

    if (item.is_billable) {

        if (!item.is_completed)
            return cogs;
        else if (item.bill !== null)
            return dollar;
        else
            return checkbox;

    } else { // Not billable

        if (item.is_completed)
            return nothing;
        else
            return cogs;
    }
};

StatusIcon.propTypes = propTypes;
export default StatusIcon;