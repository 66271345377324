import ItemEmployeeActionSummary from "../DInstrumentItem/ItemEmployeeActionSummary";
import StarRatingComponent from "react-star-rating-component";
import {TimelineEvent} from "react-event-timeline";
import React from "react";
import PropTypes from 'prop-types';
import StatusIcon from "./StatusIcon";

const propTypes = {
    rating: PropTypes.object.isRequired,
    time: PropTypes.object.isRequired
};
const RatingTimelineEvent = ({rating, editRating, time}) => {
    return (
        <>

            <TimelineEvent
                icon={<i className={"fas fa-star"}></i>}
                onIconClick={() => editRating()}
                createdAt={time}
                title={<ItemEmployeeActionSummary item={rating}/>}
            >
                <StatusIcon item={rating}/>

                <StarRatingComponent
                    name={"" + rating.id}
                    editing={false}
                    starCount={5}
                    value={rating.rating}
                />
                {rating.comment && <div><p>{rating.comment}</p></div>}
            </TimelineEvent>
        </>
    )
};
RatingTimelineEvent.propTypes = propTypes;
export default RatingTimelineEvent;
