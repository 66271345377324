import React from "react";

import PropTypes from 'prop-types';
import Tile from "./Tile";

const propTypes = {
    address: PropTypes.object
};

const CustomerAddressTile = ({address}) => {

    return (
        <Tile
            icon={
                <><a href={"https://google.com/maps/search/" + encodeURIComponent(address.line_one + ' ' + address.postal_code)} target={"_blank"}><i className={"fas fa-map"}></i></a></>
            }
            content={
                <>
                    <div><span className={"no-break"}>{address.line_one}</span></div>
                    <div><span className={"no-break"}>{address.line_two}</span></div>
                    <div><span>{address.postal_code} {address.postal_town}</span></div>
                </>
            }
        />);
};
CustomerAddressTile.propTypes = propTypes;
export default CustomerAddressTile;