import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Moment from "react-moment";
import {toast} from 'react-toastify';
import {
    ALL_ITEM_TYPES,
    TYPE_IMAGE_COLLECTION,
    TYPE_NOTE,
    TYPE_OWNERSHIP_TRANSFER,
    TYPE_RATING,
    TYPE_RENTAL_START,
    TYPE_RENTAL_STOP,
    TYPE_TECHNICAL_WORK_COLLECTION,
    TYPE_TRANSPORT,
    TYPE_TUNING
} from "../../constants";
import TuningTimeLineEvent from "./TuningTimeLineEvent";
import RatingTimelineEvent from "./RatingTimelineEvent";
import NoteTimelineEvent from "./NoteTimelineEvent";
import ImageCollectionTimeLineEvent from "./ImageCollectionTimelineEvent";
import WorkCollectionTimelineEvent from "./WorkCollectionTimelineEvent";
import RentalStartTimeLineEvent from "./RentalStartTimelineEvent";
import RentalStopTimelineEvent from "./RentalStopTimelineEvent";
import {Timeline} from "react-event-timeline";
import update from "immutability-helper";
import {Form} from "react-bootstrap";
import Switch from '@material-ui/core/Switch';
import OwnershipTransferTimelineEvent from "./OwnershipTransferTimelineEvent";
import TransportTimelineEvent from "./TransportTimelineEvent";
import Modal from "react-bootstrap/Modal";
import BillCheckbox from "../Billing/BillCheckbox";
import Badge from "react-bootstrap/Badge";
import axios from "axios";
import Button from "@material-ui/core/Button";


const propTypes = {
    items: PropTypes.array.isRequired,
    itemLimit: PropTypes.oneOf([0, 10, 25, 50]),
    editTuning: PropTypes.func,
    editTransport: PropTypes.func,
    editRating: PropTypes.func,
    editNote: PropTypes.func,
    viewImageCollection: PropTypes.func,
    editImageCollection: PropTypes.func,
    editWorkCollection: PropTypes.func,
};
const defaultProps = {
    itemLimit: 0,
    editTuning: () => {
        console.error('editTuning not set');
        toast.error('editTuning not set')
    },
    editRating: () => {
        console.error('editRating not set');
        toast.error('editRating not set');
    },
    editNote: () => {
        console.error('editNote not set');
        toast.error('editNote not set');
    },
    viewImageCollection: () => {
        console.error('viewImageCollection not set');
        toast.error('viewImageCollection not set');
    },
    editImageCollection: () => {
        console.error('editImageCollection not set');
        toast.error('editImageCollection not set');
    },
    editWorkCollection: () => {
        console.error('editImageCollection not set');
        toast.error('editImageCollection not set');
    }
};

const InstrumentTimeline = (props) => {

    const [filterIsEnabled, setFilterIsEnabled] = useState(false);
    const [billingIsEnabled, setBillingIsEnabled] = useState(false);
    const [visibleTypes, setVisibleTypes] = useState(ALL_ITEM_TYPES);
    const [billingStep, setBillingStep] = useState(0);
    {/*TODO*/
    }
    const [itemLimit, setItemLimit] = useState(1000);
    const [bill, setBill] = useState([]);
    const [sum, setSum] = useState(0);
    const [invoiceButtonDisabled, setInvoiceButtonDisabled] = useState(false);
    const [newSum, setNewSum] = useState(0);
    const [discountPercentage, setDiscountPercentage] = useState(0);
    const [discountKroner, setDiscountKroner] = useState(0);
    const [viewDiscount, setViewDiscount] = useState(false);


    const filteredItems = filterIsEnabled ? props.items.filter(
        item =>
            visibleTypes.findIndex(t => t.short === item.discr) !== -1
    ) : props.items;

    const filteredLimitedItems = (itemLimit === 0) ? filteredItems : filteredItems.slice(0, itemLimit);

    const handleClose = () => {
        setSum(0);
        setNewSum(0);
        setBillingIsEnabled(false);
        setBillingStep(0);
        setBill([]);
        setInvoiceButtonDisabled(false)
    };

    const toggleFilter = (type) => {
        const idx = visibleTypes.findIndex((element) => element.short === type.short);

        let updatedFilter;

        if (idx === -1)
            updatedFilter = update(visibleTypes, {$push: [type]});
        else
            updatedFilter = update(visibleTypes, {$splice: [[idx, 1]]});

        setVisibleTypes(updatedFilter);
    };

    const filterToggle =
        <div>
            <Button
                style={{
                    marginLeft: '5%',
                    background: '#409bf2',
                    color: 'white',
                    borderRadius: 3,
                    border: 0,
                    height: 48,
                    padding: '0 30px'
                }}
                onClick={() => setFilterIsEnabled(!filterIsEnabled)}
                variant="contained"
            >Filter</Button>

            {/*TODO: add model for viewing what is being billed, price, bill it and then set as billed to true*/}
            <Button
                style={{marginLeft: '5%',
                    background: '#555',
                    color: 'white',
                    borderRadius: 3,
                    border: 0,
                    height: 48,
                    padding: '0 30px'}}
                onClick={() => billCustomer()}
                variant='contained'
            >Send order</Button>
        </div>;

    const limiter =
        <div className={"d-flex justify-content-end"}>
            <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Limit items</Form.Label>
                {/*TODO*/}
                <Form.Control
                    value={itemLimit}
                    as="select"
                    onChange={(e) => setItemLimit(parseInt(e.target.value))}
                >
                    <option value={0}>All</option>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>

                </Form.Control>
            </Form.Group>
        </div>;


    const filterBar =
        <div
            style={{marginLeft: '5%'}}
            className="border mb-0 p-2"
        >

            {
                ALL_ITEM_TYPES.map(type => (
                    <div className={"d-flex d-md-inline-flex align-items-center"} key={type.short} style={{width: '14em'}}>
                        <div className={"mr-2"}>
                            <p style={{marginBottom: 0, marginTop: '5px', padding: 0}}><span className="pr-2" onClick={() => toggleFilter(type)}>{type.readable}</span></p>
                            <Switch
                                onChange={() => toggleFilter(type)}
                                checked={visibleTypes.findIndex(e => e.short === type.short) !== -1}
                                color="primary"
                            />
                        </div>
                    </div>
                ))
            }

        </div>;

    const billCustomer = () => {
        setBillingIsEnabled(true);
    };

    const addToBill = (item) => {
        //console.log("added bill");
        setBill(bill => [...bill, item]);
        {/*TODO: setSum blir ikke satt*/
        }
        setSum(item.price + sum);
        {/*
        setSum(item.price);
        console.log(sum);
        console.log(item.price);*/
        }
    };

    const removeFromBill = (item) => {
        //console.log("removed bill");
        const idx = bill.find(i => i.id === item);
        if (idx === -1)
            throw Error('Could not find index of item to delete with id:' + id);

        const updatedBill = update(bill, {$splice: [[idx, 1]]});
        setBill(updatedBill);
    };

    const makeInvoice = async () => {
        setInvoiceButtonDisabled(true);
        //TODO: makeInvoice and makeBill in one
        console.log(props);
        try {
            const customer = props.customer;
            const personName = customer.given_name != null ? customer.given_name + " " + customer.surname : null;
            const organizationalName = customer.organizational_name;
            const response = await axios.post('/make-invoice', {
                sum: sum,
                person_name: personName,
                organizational_name: organizationalName,
                lineOne: props.customer.line_one,
                lineTwo: props.customer.line_two,
                postalCode: props.customer.postal_code,
                postalTown: props.customer.postal_town,
                email: props.customer.contacts[0].email_address,
                address: props.address,
                post: props.post,
                bill: bill,
                dInstrumentId: props.items[0].d_instrument.id
            });
            //const invoiceId = response.data.id;
            let x;
            for (x of bill) {
                //const price = x.price;
                // console.log(invoiceId);
                //TODO: doesn't include invoice id
                /*await axios.post('/make-bill', {
                    price: price,
                    invoice_id: invoiceId
                });*/
                //set is_billed to true
                const id = x.id;
                const description = x.discr;
                if (description == "tuning") {
                    console.log("patch tuning");
                    await axios.patch('/is-billed/' + id + '/tuning');
                } else if (description == "transport") {
                    console.log("patch transport");
                    await axios.patch('/is-billed/' + id + '/transport');
                } else if (description == "technical-work-collection") {
                    console.log("patch technical work");
                    await axios.patch('/is-billed/' + id + '/technical-work');
                }
            }
            console.log(response);
            toast.success("You successfully made an invoice");
            window.location.reload();
        } catch (e) {
            console.log(e);
            toast.error("Couldn't make invoice. Make sure your dealership has all necessary information, like a bank account number.");
        }
    };

    //functions to calculate discounts to the price. They were dropped. Maybe math is wrong aswell, double check
    const discountInKroner = (discount) => {
        console.log(discount);
        setNewSum(sum - discount);//Calculates discount in kroner
        console.log(newSum);
        setDiscountKroner(discount);
        setDiscountPercentage(discount / (sum / 100));//the percentage in discount
    };

    const discountInPercent = (discount) => {
        console.log(discount);
        setNewSum(100 - (sum * ((100 - discount) / 100)));//Calculates discount in percent
        setDiscountPercentage(discount);
        setDiscountKroner(sum - newSum);//the kroners in discount
    };

    return (
        <div style={{border: "solid", borderWidth: "1px", borderColor: "#EEE", borderRadius: "10px", padding: "10px"}}>
            {limiter}
            {filterToggle}
            {filterIsEnabled && filterBar}
            {props.items.length === 0 &&
            <p className={"my-3"}>This instrument does not have any items.</p>
            }
            {props.items.length !== 0 && filteredLimitedItems.length === 0 &&
            <p className={"my-3"}>No items matches your filter.</p>
            }
            {console.log(filteredLimitedItems)}

            {/*BILLING MODAL*/}
            <Modal show={billingIsEnabled} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Invoice items</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        billingStep === 0 &&
                        <>{/*TODO: also check if it has a price */}
                            {filteredLimitedItems.map((item) => {
                                if (!item.is_billed) {
                                    const time = <Moment format={"DD.MM.YYYY HH:mm"}>{item.created}</Moment>;
                                    console.log(item);
                                    switch (item.discr) {
                                        case TYPE_TUNING:
                                            return (
                                                <>{item.is_completed && <div className={"border-bottom"}>
                                                    <p>Tuning by {item.employee.display_name}<BillCheckbox item={item}
                                                                                                           addToBill={addToBill}
                                                                                                           removeFromBill={removeFromBill}/>
                                                    </p><br/>
                                                    <p style={{fontSize: "0.7rem", color: "#AAA"}}>{time}</p>
                                                    <p>Tuned
                                                        to {item.to_frequency} Hz {item.from_frequency && <> from {item.from_frequency} Hz</>}</p>

                                                    {item.comment &&
                                                    <div className={"pt-2"}><p>{item.comment}</p></div>}
                                                    <p>{item.price ? <strong>{item.price},-</strong> : <>No
                                                        price</>}</p>
                                                </div>}
                                                </>
                                            );
                                        case TYPE_TECHNICAL_WORK_COLLECTION:
                                            return (
                                                <>{item.is_completed && <div className={"border-bottom"}>
                                                    <p>Technical work by {item.employee.display_name}<BillCheckbox
                                                        item={item} addToBill={addToBill}
                                                        removeFromBill={removeFromBill}/></p><br/>
                                                    <p style={{fontSize: "0.7rem", color: "#AAA"}}>{time}</p>
                                                    <ul>
                                                        {item.items.length === 0 ?
                                                            ''
                                                            :
                                                            item.items.map(item => <li key={"twctle" + item.id}><p
                                                                style={{fontSize: "0.7rem"}}>{item.text}</p></li>)
                                                        }
                                                    </ul>
                                                    <p>{item.price ? <strong>{item.price},-</strong> : <>No
                                                        price</>}</p>
                                                </div>}
                                                </>
                                            );
                                        case TYPE_TRANSPORT:
                                            return (
                                                <>{item.is_completed && <div className={"border-bottom"}>
                                                    <p>Transport by {item.employee.display_name}<BillCheckbox
                                                        item={item} addToBill={addToBill}
                                                        removeFromBill={removeFromBill}/></p><br/>
                                                    <p style={{fontSize: "0.7rem", color: "#AAA"}}>{time}</p>
                                                    <p>To: {item.to_address.line_one} <br/>
                                                        From: {item.from_address.line_one}<br/>

                                                        {item.comment && <span>{props.transport.comment}</span>}</p>
                                                    <p>{item.price ? <strong>{item.price},-</strong> : <>No
                                                        price</>}</p>
                                                </div>}
                                                </>
                                            );

                                        default:
                                            return <i key={Math.random()}></i>;
                                    }
                                }
                            })}
                            <div className={"d-inline-flex"}>
                                <Button variant="contained" style={{ backgroundColor: "#555", color: "white"}} onClick={handleClose} className={"m-1"}>
                                    Close
                                </Button>
                                <p><Badge variant="info" className={"m-1"}>Bills: {bill.length}</Badge></p>

                                <Button variant="contained" style={{ backgroundColor: "#409bf2", color: "white"}} onClick={() => setBillingStep(billingStep + 1)}
                                        className={"m-1"}>
                                    Next
                                </Button></div>
                        </>
                    }

                    {
                        billingStep === 1 &&
                        <>
                            {bill.map((billItem) => {
                                //.log(billItem);
                                {/*TODO: make billItems look prettier*/
                                }
                                switch (billItem.discr) {
                                    case TYPE_TUNING:
                                        return (<>
                                                <p>{billItem.discr} : {billItem.price ? <>{billItem.price},-</> : <>No
                                                    price</>}</p>
                                            </>
                                        );
                                    case TYPE_TECHNICAL_WORK_COLLECTION:
                                        return (
                                            <p>{billItem.discr} : {billItem.price ? <>{billItem.price},-</> : <>No
                                                price</>}</p>
                                        );
                                    case TYPE_TRANSPORT:
                                        return (
                                            <p>{billItem.discr} : {billItem.price ? <>{billItem.price},-</> : <>No
                                                price</>}</p>
                                        );

                                    default:
                                        return <i key={Math.random()}></i>;
                                }
                            })

                            }
                            {/*<p>Product: <i className="far fa-plus-square"></i></p>*/}
                            {/*<p>TOTAL: {() => newSum}</p>*/}
                            <div className={"border-top p-2"}>
                                {/*<Switch/>
                                    <p>Discount</p>*/}
                                {/*<div>
                                    <div className={"d-inline-flex"}>
                                    <InputGroup className="m-1" style={{maxWidth: "8rem"}} onChange={(e) => discountInKroner(e.target.value)} >
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>kr</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl value={discountKroner}/>
                                    </InputGroup>
                                    </div>
                                </div>*/}
                            </div>
                            <div>
                                <p>Billing options</p>
                                <select>
                                    <option selected value="SendForApproval ">Send for Approval</option>
                                    {/*<option value="Vipps">TBA</option>*/}
                                    {/*<option value="Invoice">TBA</option>*/}
                                </select>
                            </div>
                            <div className={"d-inline-flex"}>
                                <Button variant="secondary" onClick={handleClose} className={"m-1"}>
                                    Close
                                </Button>
                                <p><Badge variant="info" className={"m-1"}>Bills: {bill.length}</Badge></p>

                                <div><Button variant="success" disabled={invoiceButtonDisabled}
                                             onClick={() => makeInvoice()} className={"m-1"}>Send work order</Button>
                                </div>
                            </div>
                        </>
                    }
                </Modal.Body>
            </Modal>

            {/*TIMELINE*/}
            <Timeline style={{width: '90%'}}>
                {
                    filteredLimitedItems.map((item) => {
                        //console.log(item);
                        const time = <Moment format={"DD.MM.YYYY HH:mm"}>{item.created}</Moment>;
                        switch (item.discr) {
                            case TYPE_TUNING:
                                return (
                                    <TuningTimeLineEvent
                                        key={"tle" + item.id}
                                        time={time}
                                        editTuning={() => props.editTuning(item.id)}
                                        tuning={item}
                                    />
                                );
                            case TYPE_RATING:
                                return (
                                    <RatingTimelineEvent
                                        key={"tle" + item.id}
                                        time={time}
                                        editRating={() => props.editRating(item.id)}
                                        rating={item}

                                    />
                                );
                            case TYPE_NOTE:
                                return (
                                    <NoteTimelineEvent
                                        key={"tle" + item.id}
                                        time={time}
                                        editNote={() => props.editNote(item.id)}
                                        note={item}
                                    />
                                );
                            case TYPE_IMAGE_COLLECTION:
                                return (
                                    <ImageCollectionTimeLineEvent
                                        key={"tle" + item.id}
                                        time={time}
                                        viewImageCollection={() => props.viewImageCollection(item.id)}
                                        editImageCollection={() => props.editImageCollection(item.id)}
                                        imageCollection={item}

                                    />
                                );
                            case TYPE_TECHNICAL_WORK_COLLECTION:
                                return (
                                    <WorkCollectionTimelineEvent
                                        key={"tle" + item.id}
                                        time={time}
                                        editWorkCollection={() => props.editWorkCollection(item.id)}
                                        workCollection={item}
                                    />
                                );
                            case TYPE_RENTAL_START:
                                return (
                                    <RentalStartTimeLineEvent
                                        key={"tle" + item.id}
                                        rentalStart={item}
                                        time={time}
                                    />
                                );
                            case TYPE_RENTAL_STOP:
                                return (
                                    <RentalStopTimelineEvent
                                        key={"tle" + item.id}
                                        rentalStop={item}
                                        time={time}/>
                                );
                            case TYPE_OWNERSHIP_TRANSFER:
                                return (
                                    <OwnershipTransferTimelineEvent
                                        key={"tle" + item.id}
                                        transfer={item}
                                        time={time}
                                    />
                                );
                            case TYPE_TRANSPORT:
                                return (
                                    <TransportTimelineEvent
                                        key={"tle" + item.id}
                                        transport={item}
                                        editTransport={() => props.editTransport(item.id)}
                                        time={time}
                                    />
                                );

                            default:
                                console.log('Implement mapping for ', item);
                                return <i key={Math.random()}>default</i>;
                        }
                    })
                }
            </Timeline>
        </div>
    );
};
InstrumentTimeline.propTypes = propTypes;
InstrumentTimeline.defaultProps = defaultProps;
export default InstrumentTimeline;