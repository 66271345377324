import React, {useState} from "react";
import axios from 'axios';
import PropTypes from 'prop-types';
import {Button, Col, Form, FormGroup, FormLabel, Row} from "react-bootstrap";
import {toast} from 'react-toastify';

const propTypes = {
    customer: PropTypes.object.isRequired,
    setCustomer: PropTypes.func.isRequired
};

const EditCustomerEmail = ({customer, setCustomer}) => {

    const [isEditing, setIsEditing] = useState(false);
    const [address, setAddress] = useState(customer.addresses[0].line_one);
    const [postalCode, setPostalCode] = useState(customer.addresses[0].postal_code);
    const [isUpdating, setIsUpdating] = useState(false);

    const updateEmail = async () => {
        setIsUpdating(true);
        try {
            await axios.patch('/api/customer-patch/' + customer.id + '/address', {
                address: address,
                postalCode: postalCode
            });
            setIsEditing(false);
        } catch (e) {
            console.error(e);
            toast.error(e.message);
        }
        toast.success('Address was updated.');
        setIsUpdating(false);
        window.location.reload();
    };

    const editView = <>
        <FormGroup>
            <FormLabel>Address:</FormLabel>
            <Form.Control type={"text"} value={address || ''} onChange={(e) => setAddress(e.target.value)}/>
            <Form.Control type={"text"} value={postalCode || ''} onChange={(e) => setPostalCode(e.target.value)}/>
        </FormGroup>
        <div className={"d-flex justify-content-between"}>
            <Button variant={"danger"} onClick={() => setIsEditing(false)}>Cancel</Button>
            <Button variant={"success"} onClick={() => updateEmail()} disabled={isUpdating}>
                {isUpdating ? <><i className={"fas fa-spinner fa-spin pr-1"}></i> Saving</> : 'Save'}
            </Button>
        </div>
    </>;

    const noEditView =
        <Row>
            <Col xs={4}><label>Address:</label></Col>
            <Col xs={6}>{customer.addresses[0].line_one}</Col>
            <Col xs={2}><Button size={"sm"}
                                variant={"link"}
                                onClick={() => setIsEditing(true)}>Edit</Button></Col>
        </Row>;

    return (
        <>
            {isEditing ? editView : noEditView}
        </>
    )
};
EditCustomerEmail.propTypes = propTypes;
export default EditCustomerEmail;