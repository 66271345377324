import React from 'react';
import PropTypes from 'prop-types';
import ItemEmployeeActionSummary from "../DInstrumentItem/ItemEmployeeActionSummary";
import {TimelineEvent} from "react-event-timeline";
import StatusIcon from "./StatusIcon";

const propTypes = {
    transport: PropTypes.object.isRequired,
    time: PropTypes.object.isRequired,
};
const TransportTimelineEvent = (props) => {

    return (
        <>
            <TimelineEvent
                icon={<i className={"fas fa-truck-moving"}></i>}
                onIconClick={() => props.editTransport()}
                createdAt={props.time}
                title={<ItemEmployeeActionSummary item={props.transport}/>}
            >
                <StatusIcon item={props.transport}/>

                <p>
                    Transported.<br/>
                    <br/>

                    To: {props.transport.to_address.line_one} <br/>
                    From: {props.transport.from_address.line_one}

                    {props.transport.comment && <span>{props.transport.comment}</span>}
                </p>
            </TimelineEvent>
        </>
    );
};
TransportTimelineEvent.propTypes = propTypes;
export default TransportTimelineEvent;