import React, {useState} from "react";


const BillCheckbox = (props) => {
    const [isToggled, setIsToggled] = useState(false);
    return (
        <input type={"checkbox"} className={"float-right"}
               onClick={() => {isToggled ? <>{props.removeFromBill(props.item)}{setIsToggled(false)}</> : <>{props.addToBill(props.item)}{setIsToggled(true)}</>}}/>
    )
};
export default BillCheckbox;