export const ERROR_MESSAGE_IF_PERSISTS_CONTACT_SUPPORT = 'Please try the action again. If the error keeps occurring, please contact support at <a href="mailto:support@pianoserviceprofile.no">support@pianoserviceprofile.no</a>';
export const DELAY_BEFORE_REDIRECT = 2000; // ms

export const RATING_COMMENT_MAX_LENGTH = 255;

export const IMAGE_UPLOAD_FOLDER = '/uploads';
export const IMAGE_UPLOAD_THUMBNAIL_FOLDER = '/uploads/thumbnails';

export const TYPE_TUNING = 'tuning';
export const TYPE_RATING = 'rating';
export const TYPE_NOTE = 'note';
export const TYPE_IMAGE_COLLECTION = 'image-collection';
export const TYPE_TECHNICAL_WORK_COLLECTION = 'technical-work-collection';
export const TYPE_RENTAL_START = 'rental-start';
export const TYPE_RENTAL_STOP = 'rental-stop';
export const TYPE_OWNERSHIP_TRANSFER = 'ownership-transfer';
export const TYPE_TRANSPORT = 'transport';

export const ALL_ITEM_TYPES = [
    {short: TYPE_TUNING, readable: 'Tunings'},
    {short: TYPE_RATING, readable: 'Ratings'},
    {short: TYPE_NOTE, readable: 'Notes'},
    {short: TYPE_IMAGE_COLLECTION, readable: 'Image Collections'},
    {short: TYPE_TECHNICAL_WORK_COLLECTION, readable: 'Technical Work'},
    {short: TYPE_RENTAL_START, readable: 'Rental Relationship Start'},
    {short: TYPE_RENTAL_STOP, readable: 'Rental Relationship Stop'},
    {short: TYPE_OWNERSHIP_TRANSFER, readable: 'Ownership Transfer'},
    {short: TYPE_TRANSPORT, readable: 'Transport'}
];

