import React from "react";
import PropTypes from "prop-types";

const propTypes = {
    icon: PropTypes.object.isRequired,
    content: PropTypes.object.isRequired
};
const Tile = ({icon, content}) => {
    return (
        <div className={"d-flex border rounded tile"}>
            <div className={"align-self-center pl-3"}>
                {icon}
            </div>
            <div className={"align-self-center p-3"}>
                {content}
            </div>
        </div>
    );
};
Tile.propTypes = propTypes;
export default Tile;