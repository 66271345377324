import React from 'react';
import ItemEmployeeActionSummary from "../DInstrumentItem/ItemEmployeeActionSummary";
import {TimelineEvent} from "react-event-timeline";
import PropTypes from 'prop-types';
import Button from "react-bootstrap/Button";
import StatusIcon from "./StatusIcon";
import UploadedImage from "./UploadedImage";


const propTypes = {
    viewImageCollection: PropTypes.func.isRequired,
    editImageCollection: PropTypes.func.isRequired,
    imageCollection: PropTypes.object.isRequired,
    time: PropTypes.object.isRequired,
};

const ImageCollectionTimeLineEvent = ({imageCollection, editImageCollection, viewImageCollection, time}) => {
    return (
        <>

            <TimelineEvent
                icon={<i className={"fas fa-camera"}></i>}
                onIconClick={() => {editImageCollection(imageCollection.item_id)}}
                createdAt={time}
                title={<ItemEmployeeActionSummary item={imageCollection}/>}
            >
                <StatusIcon item={imageCollection}/>
                {imageCollection.comment !== null && <p>{imageCollection.comment}</p>}
                {imageCollection.images && imageCollection.images.length === 0 &&
                <p>No images in this collection yet.</p>}
                {imageCollection.images && imageCollection.images.length !== 0 && <>
                    <div className={"d-flex flex-wrap mb-2"}>
                        {imageCollection.images.map(image => (
                            <UploadedImage
                                image={image}
                                key={"tliass" + image.id}
                            />))}
                    </div>
                    <div className={"d-flex justify-content-end"}>
                        <Button
                            variant="outline-primary"
                            size={"sm"}
                            onClick={() => viewImageCollection(imageCollection.id)}>View
                            Collection</Button>
                    </div>

                </>}

            </TimelineEvent>
        </>
    );
};
ImageCollectionTimeLineEvent.propTypes = propTypes;
export default ImageCollectionTimeLineEvent;