import React, {useState, useEffect} from 'react';
import axios from 'axios';
import 'babel-polyfill';
import {toast} from 'react-toastify';

const useEndUserInstrumentItems = (token, dInstrumentId) => {

    const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [error, setError] = useState(null);
    const [refetchTriggerFlag, _triggerRefetch] = useState(false);

    const refetch = () => {
        _triggerRefetch(!refetchTriggerFlag);
    };

    useEffect(() => {
        (async () => {
            if (dInstrumentId === null) {
                setItems(null);
                return;
            }

            try {
                setIsLoading(true);
                const response = await axios.get('/access-instrument-data/' + dInstrumentId + '/' + token);
                setItems(response.data);
                console.log(response)
            } catch (e) {
                console.error(e);
                toast.error(e.message);
                setError(e);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [dInstrumentId, refetchTriggerFlag]);

    return {items, isLoading, error, refetch}
};
export default useEndUserInstrumentItems;