import React from 'react';
import Tile from "./Tile";

const ParentCustomerTile = ({customer}) => {

    console.log(customer);
    return (
        <Tile
            icon={
                <i className={"fas fa-network-wired"}></i>
            }
            content={
                <>
                    <h6>{customer.organizational_name}</h6>
                    <div>
                        <span>{customer.number_of_instruments} {customer.number_of_instruments === 1 ? 'instrument' : 'instruments'}</span>
                    </div>
                    <div><a href={"/customer/" + customer.id}>Go to parent institution</a></div>
                </>
            }
        />
    );
};
export default ParentCustomerTile;