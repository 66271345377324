import PropTypes from 'prop-types';
import React from "react";
import {
    TYPE_IMAGE_COLLECTION,
    TYPE_NOTE,
    TYPE_OWNERSHIP_TRANSFER,
    TYPE_RATING,
    TYPE_RENTAL_START,
    TYPE_RENTAL_STOP,
    TYPE_TECHNICAL_WORK_COLLECTION,
    TYPE_TRANSPORT,
    TYPE_TUNING
} from "../../constants";
import CustomerName from "../Customer/CustomerName";

/**
 *  Tarald R rated the instrument
 * @param props
 * @constructor
 */

const container = {
    marginRight: '2%'
};

const actionStyle = {
    fontWeight: 'bold',
    color: '#a3a3a3',
};

const customerStyle = {
    textAlign: 'right',
    color: '#a3a3a3'
};

const propTypes = {
    item: PropTypes.object.isRequired
};
const ItemEmployeeActionSummary = ({item}) => {

    let pre;
    switch (item.discr) {
        case TYPE_RATING:
            pre = <>The instrument was rated</>;
            break;
        case TYPE_TUNING:
            pre = <>The instrument was tuned</>;
            break;
        case TYPE_NOTE:
            pre = <>A note was added</>;
            break;
        case TYPE_IMAGE_COLLECTION:
            pre = <>An image collection was added</>;
            break;
        case TYPE_TECHNICAL_WORK_COLLECTION:
            pre = <>Technical work was added</>;
            break;
        case TYPE_RENTAL_START:
            pre = <>The instrument was rented out</>;
            break;
        case TYPE_RENTAL_STOP:
            pre = <>The instrument was returned from being rented</>;
            break;
        case TYPE_OWNERSHIP_TRANSFER:
            pre = <>The instrument changed owner</>;
            break;
        case TYPE_TRANSPORT:
            pre = <>The instrument was transported</>;
            break;
        default:
            return null;
    }


    if (item.employee !== null) {
        pre = <>{pre} by <i>{item.employee.display_name}</i></>
    } else {
        pre = <>{pre}</>
    }

    if (item.customer !== null) {
        return (
            <div style={container} className={"d-flex justify-content-between"}>
                <div style={actionStyle}>
                    {pre}.
                </div>
                <div style={customerStyle}>
                    <CustomerName customer={item.customer}/>
                </div>
            </div>
        );
    } else {
        return (
            <div style={container}>
                <div style={actionStyle}>
                    {pre}.
                </div>
            </div>
        );
    }
};

ItemEmployeeActionSummary.propTypes = propTypes;
export default ItemEmployeeActionSummary;