import React from "react";
import PropTypes from "prop-types";
import Tile from "./Tile";


const propTypes = {
    customer: PropTypes.object.isRequired
};
const ChildCustomerTile = ({customer: childCustomer}) => {
    return (
        <Tile
            icon={<i className={"fas fa-network-wired"}></i>}
            content={
                <>

                    <div><h6>{childCustomer.organizational_name}</h6></div>
                    <div><span>{childCustomer.number_of_instruments} {childCustomer.number_of_instruments === 1 ? 'instrument' : 'instruments'}</span></div>
                    <div><a href={"/customer/" + childCustomer.id}>Go to child institution</a></div>
                </>
            }></Tile>
    );
};
ChildCustomerTile.propTypes = propTypes;
export default ChildCustomerTile;