import React from 'react';
import PropTypes from 'prop-types';
import ItemEmployeeActionSummary from "../DInstrumentItem/ItemEmployeeActionSummary";
import {TimelineEvent} from "react-event-timeline";
import StatusIcon from "./StatusIcon";

const propTypes = {
    workCollection: PropTypes.object.isRequired,
    time: PropTypes.object.isRequired,
    editWorkCollection: PropTypes.func.isRequired,
};


const WorkCollectionTimelineEvent = ({workCollection, editWorkCollection, time}) => {

    let elapsed = '';

    if (workCollection.hours)
        elapsed += workCollection.hours + ' hours';
    if (workCollection.hours && workCollection.minutes)
        elapsed += ' and ';
    if (workCollection.minutes)
        elapsed += workCollection.minutes + ' minutes';

    return (
        <>
            <TimelineEvent
                icon={<i className={"fas fa-wrench"}></i>}
                onIconClick={() => editWorkCollection()}
                createdAt={time}
                title={<ItemEmployeeActionSummary item={workCollection}/>}
            >
                <StatusIcon item={workCollection}/>
                {workCollection.title &&
                <span><strong>{workCollection.title}</strong></span>
                }
                <ul>
                    {workCollection.items.length === 0 ?
                        ''
                        :
                        workCollection.items.map(item => <li key={"twctle" + item.id}>{item.text}</li>)
                    }
                </ul>
                {(workCollection.hours || workCollection.minutes) &&
                <span>{elapsed}</span>
                }
            </TimelineEvent>
        </>
    );
};
WorkCollectionTimelineEvent.propTypes = propTypes;
export default WorkCollectionTimelineEvent;